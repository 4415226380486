// opcoes: localhost, dev, prod, stage

const getVariablesByEnv = () => {
  switch (process.env.REACT_APP_AMB) {
    case 'local':
      return {
        server: 'http://172.25.100.132:30070/auditoria',
        authServer: 'https://spca-dev.sspds.ce.gov.br/api/app/auth',
        prefixRoute: 'web/meucelular',
      };
    case 'dev': {
      return {
        server: 'https://spca-dev.sspds.ce.gov.br/api/app/alerta',
        authServer: 'https://spca-dev.sspds.ce.gov.br/api/app/auth',
        appKey: '7f0a25a4cae56106112a63bee8bc0cc9',
        accessCitizenServer: 'https://staging.seplag.ce.gov.br',
        prefixRoute: 'web/meucelular',
      };
    }
    case 'prod': {
      return {
        server: 'https://spca.sspds.ce.gov.br/api/app/alerta',
        authServer: 'https://spca.sspds.ce.gov.br/api/app/auth',
        appKey: 'c2b6ac4b390592222f6a304e0d803591',
        accessCitizenServer: 'https://acesso.cearadigital.ce.gov.br',
        prefixRoute: '',
      };
    }
    default:
      return {
        server: 'https://spca-dev.sspds.ce.gov.br/api/app/alerta',
        authServer: 'https://spca-dev.sspds.ce.gov.br/api/app/auth',
        prefixRoute: 'web/meucelular',
      };
  }
};

export default {
  ...getVariablesByEnv(),
};
