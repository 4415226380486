import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useGlobalData } from '../../hooks/globalData';
import cellphoneService from '../../services/cellphone';
import { Container } from './styles';
import Slider from 'components/slider';
import Modals from './modals';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/auth';
import decodeJwt from 'utils/decodeJwt';
import { LgpdModal } from 'components/modals/lgpd';
import { AuthService } from 'services/auth';
import AddUserInfoModal from 'components/modals/addUserInfo';

toast.configure();

type THasAccessToTheSystem = {
  190: boolean;
  alerta: boolean;
};

export type TPendingsuser = {
  id: number;
  label: string;
  value: boolean;
};
export default function Home() {
  const [loading, setLoading] = useState(false);
  const isAlreadyAgree =
    sessionStorage.getItem('AgreeWithAuthenticationChange') === 'true';
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showBOModal, setShowBOModal] = useState(false);
  const [showRestitutionModal, setShowRestitutionModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLgpdModal, setShowLgpdModal] = useState(false);
  const [showChangeAuthentication, setShowChangeAuthentication] =
    useState(false);
  const [showAddUserInfoModal, setShowAddUserInfoModal] = useState(false);
  const [pendingUserInfo, setPendingUserInfo] = useState<TPendingsuser[]>([
    {
      id: 1,
      label: 'Validar CPF',
      value: false,
    },
    {
      id: 2,
      label: 'Informar Endereço',
      value: false,
    },
    {
      id: 3,
      label: 'Informar telefones para contato',
      value: false,
    },
  ]);
  const [CardId, setCardId] = useState('');

  const { client } = useAuth();
  const isLoggedByAcessCitizen = sessionStorage.getItem(
    '@SSPDS_ALERTA:isLoggedByAcessCitizen'
  );
  const isFirstAccess = sessionStorage.getItem('firstAccess');
  const alreadyAgreedWithLgpd =
    sessionStorage.getItem('hasAgreedWithLgpd') === 'true';
  const tokenDecoded = decodeJwt(
    sessionStorage.getItem('@SSPDS_ALERTA:token') || ''
  );
  const hasAccessToTheSystem: THasAccessToTheSystem = {
    190: tokenDecoded?.role?.includes('ROLE_190_OCORRENCIA_PANICO'),
    alerta: tokenDecoded?.role?.includes('ROLE_ALERTA_CELULAR_CONTA_DEFAULT'),
  };

  const {
    aparelhos,
    setAparelhos,
    ocorrencias,
    setOcorrencias,
    // procedimentos,
    // setProcedimentos,
  } = useGlobalData();

  const { getAllFromUser, getStatus, getProcedimentoType } = cellphoneService();

  const { hasAgreedWithLgpd } = AuthService();

  const [showEditUserModal, setShowEditUserModal] = useState(false);

  const navigate = useNavigate();

  const initFetch = useCallback(
    async (isMounted: boolean) => {
      try {
        setLoading(true);
        if (!isLoggedByAcessCitizen && !isAlreadyAgree) {
          setShowChangeAuthentication(true);
        }
        if (!isMounted) {
          const [status, allDevices] = await Promise.all([
            getStatus(),
            // getProcedimentoType(),
            getAllFromUser(),
          ]);
          //@ts-ignore
          const allDevicesFormated = allDevices?.data?.sort(
            (x: any, y: any) => {
              let a = x.statusAlerta,
                b = y.statusAlerta;
              return a == b ? 0 : a > b ? 1 : -1;
            }
          );

          const ocurrencesFiltered: any[] =
            status?.data?.filter(
              (situation: string) => situation !== 'SEM_STATUS'
            ) || [];

          setOcorrencias(ocurrencesFiltered);
          // setProcedimentos(procedureType?.data || []);
          setAparelhos(allDevicesFormated || []);
        }
      } catch (err: any) {
        const isBronzeUser = err?.message.includes('BRONZE');
        const dontHaveAdressRegistered = err?.message.includes(
          'endereço cadastrado'
        );
        const dontHavePhoneNumber = err?.message.includes('telefones');
        if (err?.message.includes('Falha na validação')) {
          const updates: any[] = [];
          if (isBronzeUser) {
            return setShowAddUserInfoModal(true);
          }
          if (dontHaveAdressRegistered || dontHavePhoneNumber) {
            updates.push({ id: 1, value: true });
          }
          if (!dontHaveAdressRegistered) {
            updates.push({ id: 2, value: true });
          }
          if (!dontHavePhoneNumber) {
            updates.push({ id: 3, value: true });
          }
          updates.length &&
            setPendingUserInfo(prev =>
              prev.map(element =>
                updates?.find(update => update.id === element.id)
                  ? { ...element, value: true }
                  : element
              )
            );
          return setShowAddUserInfoModal(true);
        }
        if (err?.message === 'Nenhum celular encontrado') {
          navigate('/registrar-dispositivo');
        } else {
          toast.error('Erro ao carregar dispositivos');
        }
      } finally {
        setLoading(false);
      }
    },
    [
      setLoading,
      getStatus,
      getProcedimentoType,
      getAllFromUser,
      setOcorrencias,
      // setProcedimentos,
      setAparelhos,
    ]
  );

  const verifyLgpd = useCallback(async () => {
    try {
      setLoading(true);
      const cpf = client?.cpf;
      if (cpf) {
        const userAgreeWithLgpd = await hasAgreedWithLgpd(cpf);
        if (!userAgreeWithLgpd?.data?.accepted) {
          setShowLgpdModal(true);
        } else {
          sessionStorage.setItem('hasAgreedWithLgpd', 'true');
        }
      } else {
        throw new Error('invalid cpf');
      }
    } catch (err) {
      console.error(err);
      toast.error(
        'Ocorreu um erro com o sistema, por favor contate o suporte técnico'
      );
    } finally {
      setLoading(false);
    }
  }, []);

  const handleCancelModalEditUser = () => {
    sessionStorage.removeItem('firstAccess');
    setShowEditUserModal(false);
    initFetch(false);
  };

  useEffect(() => {
    let isMounted = false;
    !alreadyAgreedWithLgpd && verifyLgpd();
    initFetch(isMounted);

    return () => {
      isMounted = true;
    };
  }, []);

  return (
    <Container>
      <Spin spinning={loading}>
        <Slider
          data={aparelhos}
          setShowAlertModal={setShowAlertModal}
          setShowBOModal={setShowBOModal}
          setShowRestitutionModal={setShowRestitutionModal}
          setShowDeleteModal={setShowDeleteModal}
          setCardId={setCardId}
        />
      </Spin>
      <Modals
        showAlertModal={showAlertModal}
        showBOModal={showBOModal}
        showRestitutionModal={showRestitutionModal}
        showDeleteModal={showDeleteModal}
        showChangeAuthentication={showChangeAuthentication}
        cardId={CardId}
        setShowChangeAuthentication={setShowChangeAuthentication}
        setShowAlertModal={setShowAlertModal}
        setShowBOModal={setShowBOModal}
        setShowRestitutionModal={setShowRestitutionModal}
        setShowDeleteModal={setShowDeleteModal}
        // procedure={procedimentos}
        ocurrences={ocorrencias}
        initFetch={initFetch}
      />

      <Modal
        title="Atualizar dados"
        visible={showEditUserModal}
        okText="Sim"
        cancelText="Agora não"
        onOk={() => navigate(`/registrar-usuario/${client?.cpf}`)}
        onCancel={handleCancelModalEditUser}
      >
        <p>Bem vindo ao Meu Celular, deseja atualizar seus dados cadastrais?</p>
      </Modal>

      <LgpdModal
        isVisible={showLgpdModal}
        setIsVisible={setShowLgpdModal}
        isLogged={true}
      />
      <AddUserInfoModal
        isModalOpen={showAddUserInfoModal}
        setIsModalOpen={setShowAddUserInfoModal}
        pendings={pendingUserInfo}
      />
    </Container>
  );

  // return (
  //   <Wrapper className="wrapper">
  //     <div className="container">
  //       <Spin spinning={loading}>
  //         <Slider
  //           data={aparelhos}
  //           setShowAlertModal = {setShowAlertModal}
  //           setShowBOModal = {setShowBOModal}
  //           setShowRestitutionModal = {setShowRestitutionModal}
  //           setShowDeleteModal = {setShowDeleteModal}
  //           setCardId = {setCardId}
  //           // setModalVisibility={updateModalVisibility}
  //           // showModal={showModal}
  //           // showDeleteModal={showDeleteModal}
  //           // showModalRestituicao ={showModalRestituicao}
  //         />
  //       </Spin>

  //       {/* <div style={{display: 'flex', justifyContent: 'center', padding:'20px', gap:'20px', flexWrap:'wrap'}}>
  //         <Button onClick={() => showModal('10317', 'PRE_ALERTA')}>open older pre alert modal</Button>

  //         <Button onClick={() => showModal('10317', 'ALERT')}>open older alert modal</Button>

  //         <Button onClick={() => showModalRestituicao('10317')}>open older restitution modal</Button>

  //         <Button onClick={() => showDeleteModal('10317')}>open older delete modal</Button>
  //       </div> */}

  //       <Modals
  //         showAlertModal = {showAlertModal}
  //         showBOModal = {showBOModal}
  //         showRestitutionModal = {showRestitutionModal}
  //         showDeleteModal = {showDeleteModal}
  //         cardId = {CardId}
  //         setShowAlertModal = {setShowAlertModal}
  //         setShowBOModal = {setShowBOModal}
  //         setShowRestitutionModal = {setShowRestitutionModal}
  //         setShowDeleteModal = {setShowDeleteModal}
  //         procedure={procedimentos}
  //         ocurrences={ocorrencias}
  //         initFetch={initFetch}
  //       />
  //       {/* <div className="row" style={{ position: 'relative' }}> */}
  //         {/* {loading && <LoadingComponent />} */}

  //         {/* <Modal
  //           destroyOnClose
  //           title={
  //             preencheJustificativa
  //               ? isPreAlerta
  //                 ? 'Cancelar Pré-Alerta'
  //                 : 'Cancelar Alerta'
  //               : restituicao
  //               ? ' Registrar Restituição'
  //               : 'Acionar Alerta'
  //           }
  //           visible={isModalVisible}
  //           onCancel={handleCancel}
  //           footer={[]}
  //           style={{ position: 'relative' }}
  //         >
  //           {loading && <LoadingComponent />}
  //           <div className="conteudoModal" style={{ zIndex: -1 }}>
  //             <Form form={form} onFinish={handleOk}>
  //               {' '}
  //               {!preencheJustificativa ? (
  //                 <div>
  //                   <p>
  //                     Preencha os campos para confirmar o estado de{' '}
  //                     <b
  //                       style={{
  //                         color: '#008442',
  //                       }}
  //                     >
  //                       {' '}
  //                       {situacao()}{' '}
  //                     </b>{' '}
  //                     deste dispositivo.{' '}
  //                   </p>{' '}
  //                   <div
  //                     style={{
  //                       marginBottom: '8px',
  //                     }}
  //                   >
  //                     {!restituicao && !isPreAlerta ? (
  //                       <Checkbox
  //                         // className={styles.labelCheck}
  //                         checked={possuiBO}
  //                         value={possuiBO}
  //                         // disabled={possuiCodigo}
  //                         onChange={() => alteraStatusBo()}
  //                       >
  //                         Possuo número do procedimento{' '}
  //                       </Checkbox>
  //                     ) : (
  //                       <div />
  //                     )}
  //                   </div>
  //                   <div
  //                     style={{
  //                       marginBottom: '20px',
  //                     }}
  //                   >
  //                     {possuiBO && !restituicao ? (
  //                       <Checkbox
  //                         checked={possuiChave}
  //                         value={possuiChave}
  //                         // disabled={!possuiBO}
  //                         onChange={() => alteraStatusPossuiChave()}
  //                       >
  //                         Possuo número do alerta celular{' '}
  //                       </Checkbox>
  //                     ) : (
  //                       <div />
  //                     )}
  //                   </div>
  //                   {!possuiBO ? (
  //                     <p
  //                       style={{
  //                         background: '#dfdfdf',
  //                         padding: 5,
  //                       }}
  //                     >
  //                       * Obs: Você deve adicionar os campos obrigatórios no
  //                       prazo de até 72 h para que seu dispositivo entre no modo
  //                       Alertado.{' '}
  //                     </p>
  //                   ) : (
  //                     <div />
  //                   )}
  //                   {possuiBO ? (
  //                     <div>
  //                       <FormItem
  //                         name="tipoProcedimento"
  //                         style={{
  //                           width: '50%',
  //                         }}
  //                       >
  //                         <Select placeholder="Procedimento">
  //                           {' '}
  //                           {procedimentos.map((pro: any) => (
  //                             <Option key={pro} value={pro}>
  //                               {' '}
  //                               {pro.replaceAll('_', ' ')}{' '}
  //                             </Option>
  //                           ))}{' '}
  //                         </Select>
  //                       </FormItem>
  //                       <Image width="30%" src={imgExemplo} />
  //                       <Input.Group compact>
  //                         <FormItem
  //                           name="delegacia"
  //                           style={{
  //                             width: '33%',
  //                           }}
  //                         >
  //                           <Input placeholder="Nº Delegacia" />
  //                         </FormItem>{' '}
  //                         <FormItem
  //                           name="sequencial"
  //                           style={{
  //                             width: '33%',
  //                           }}
  //                         >
  //                           <Input placeholder="Nº Sequencial" />
  //                         </FormItem>{' '}
  //                         <FormItem
  //                           name="ano"
  //                           style={{
  //                             width: '33%',
  //                           }}
  //                         >
  //                           <Input placeholder="Ano" />
  //                         </FormItem>{' '}
  //                       </Input.Group>{' '}
  //                     </div>
  //                   ) : (
  //                     <div />
  //                   )}
  //                   {!restituicao && !possuiChave ? (
  //                     <Input.Group compact>
  //                       <FormItem
  //                         name="dataOcorrencia"
  //                         style={{
  //                           width: '50%',
  //                         }}
  //                       >
  //                         <DatePicker
  //                           showTime
  //                           style={{
  //                             width: '100%',
  //                             height: '32px',
  //                           }}
  //                           format="DD/MM/YYYY HH:mm:ss"
  //                           placeholder="Data da ocorrência"
  //                         />
  //                       </FormItem>{' '}
  //                       <FormItem
  //                         name="situacaoCelular"
  //                         style={{
  //                           width: '50%',
  //                         }}
  //                       >
  //                         <Select placeholder="Situação da ocorrência">
  //                           {' '}
  //                           {ocorrencias?.map((oco: any) => (
  //                             <Option key={oco} value={oco}>
  //                               {' '}
  //                               {oco}{' '}
  //                             </Option>
  //                           ))}{' '}
  //                         </Select>
  //                       </FormItem>{' '}
  //                     </Input.Group>
  //                   ) : (
  //                     <FormItem
  //                       name="situacaoCelular"
  //                       style={{
  //                         width: '50%',
  //                       }}
  //                     >
  //                       <Select placeholder="Situação da ocorrência">
  //                         {' '}
  //                         {ocorrencias?.map((oco: any) => (
  //                           <Option key={oco} value={oco}>
  //                             {' '}
  //                             {oco}{' '}
  //                           </Option>
  //                         ))}{' '}
  //                       </Select>
  //                     </FormItem>
  //                   )}
  //                   {possuiChave && (
  //                     <Input.Group compact>
  //                       <FormItem
  //                         name="chaveObjeto"
  //                         style={{
  //                           width: '100%',
  //                         }}
  //                       >
  //                         <Input placeholder="Número do alerta celular." />
  //                       </FormItem>{' '}
  //                     </Input.Group>
  //                   )}{' '}
  //                   <p
  //                     style={{
  //                       background: '#941c1c',
  //                       padding: '10px',

  //                       color: '#fff',
  //                     }}
  //                   >
  //                     Art .340 - Provocar a ação de autoridade, comunicando -
  //                     lhe a ocorrência de crime ou de contravenção que sabe não
  //                     se ter verificado: Pena - detenção, de um a seis meses, ou
  //                     multa....Comunicar falso crime a quem é inocente, para que
  //                     seja investigado e processado é crime.{' '}
  //                   </p>{' '}
  //                 </div>
  //               ) : (
  //                 <FormItem name="justificativa">
  //                   <TextArea placeholder="Justificar Cancelamento" />
  //                 </FormItem>
  //               )}{' '}
  //               <br />
  //               <br />{' '}
  //               {restituicao ? (
  //                 <Button key="submit" type="primary" htmlType="submit">
  //                   Confirmar
  //                 </Button>
  //               ) : (
  //                 <Button
  //                   key="submit"
  //                   type="primary"
  //                   htmlType="submit"
  //                   style={{
  //                     marginRight: '10px',
  //                   }}
  //                 >
  //                   Confirmar{' '}
  //                 </Button>
  //               )}{' '}
  //               {!preencheJustificativa && (
  //                 <Button
  //                   style={{
  //                     color: '#FF9B05',
  //                   }}
  //                   onClick={() => {
  //                     handleCancelAlert();
  //                   }}
  //                 >
  //                   {isPreAlerta
  //                     ? 'Cancelar Pré-Alerta'
  //                     : restituicao
  //                     ? 'Cancelar Alerta'
  //                     : ' '}{' '}
  //                 </Button>
  //               )}{' '}
  //               {preencheJustificativa ? (
  //                 <Button onClick={() => setPreJustificativa(false)}>
  //                   Cancelar{' '}
  //                 </Button>
  //               ) : (
  //                 ''
  //               )}{' '}
  //             </Form>
  //           </div>
  //         </Modal> */}

  //         {/* <Modal
  //           title="Excluir dispositivo"
  //           visible={isModalDeleteVisible}
  //           onOk={handleDeleteOk}
  //           onCancel={handleCancel}
  //         >
  //           <p
  //             style={{
  //               display: 'flex',
  //               fontSize: '16px',
  //               alignItems: 'center',
  //               textAlign: 'center',
  //             }}
  //           >
  //             <AlertTwoTone
  //               twoToneColor="red"
  //               style={{
  //                 fontSize: '30px',
  //                 marginRight: '15px',
  //                 marginBottom: '6px',
  //               }}
  //             />{' '}
  //             Você confima a exclusão deste dispositivo ?
  //           </p>
  //         </Modal> */}
  //     </div>{' '}
  //   </Wrapper>
  // );
}
